<template>
  <div>
    <app-navigation />
    <v-main>
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </transition>
    </v-main>
  </div>
</template>

<script>
import AppNavigation from '@/components/shared/Navigation';

export default {
  name: 'HomeTemplate',
  components: {
    AppNavigation,
  },
};
</script>
