<template>
  <div class="navbar__wrapper">
    <v-navigation-drawer
      v-model="sidebarMenu"
      clipped
      app
      color="nav"
      dark
      :mini-variant="navigationMini"
      :value="navigationValue"
    >
      <v-list-item
        v-if="$vuetify.breakpoint.mdAndDown"
        class="bg--nav-lighten1"
      >
        <v-list-item-content class="pb-2">
          <v-list-item-title class="d-flex align-center">
            <a
              href="/"
              class="d-flex"
            >
              <img
                src="/img/nubowms-white.svg"
                alt=""
                :class="(!navigationMini && 'logo') || 'logo--small'"
              />
            </a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div class="list__wrapper">
        <v-list
          dense
          class="pa-0"
        >
          <template v-for="item in items">
            <v-list-item
              v-if="!item.items"
              :key="item.title"
              :href="item.to"
              link
              class="mb-0"
              color="white"
              active-class="nav lighten-2"
              :class="$route.meta.navigationParent === item.title && 'v-list-item--active'"
            >
              <v-list-item-icon>
                <v-icon color="white">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text--nav-lighten5">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              :key="item.title"
              v-model="item.active"
              value="false"
              active-class="nav lighten-2"
              color="white"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="white">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text--nav-lighten5">
                  {{ item.title }}
                </v-list-item-title>
              </template>
              <v-list
                dense
                class="pa-0"
              >
                <template v-for="subItem in item.items">
                  <v-list-item
                    v-if="!subItem.items"
                    :key="subItem.title"
                    :href="subItem.to"
                    link
                    class="mb-0 navigation_group base"
                    color="white"
                    active-class="v-list-item--active"
                    :class="$route.meta.navigationParent === subItem.title && 'v-list-item--active'"
                  >
                    <v-list-item-icon>
                      <v-icon color="white">
                        {{ subItem.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text--nav-lighten5">
                        {{ subItem.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-group
                    v-else
                    :key="subItem.title"
                    v-model="subItem.active"
                    value="false"
                    active-class="nav lighten-2"
                    color="white"
                  >
                    <template v-slot:activator>
                      <v-list-item-icon @click="navigationMini && swtichMenu()">
                        <v-icon color="white">
                          {{ subItem.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text--nav-lighten5">
                        {{ subItem.title }}
                      </v-list-item-title>
                    </template>
                    <v-list
                      v-if="!navigationMini"
                      dense
                      class="pa-0"
                    >
                      <v-list-item
                        v-for="subItemItem in subItem.items"
                        :key="subItemItem.title"
                        color="white"
                        :href="subItemItem.to"
                        link
                        class="mb-0 px-1"
                        :class="
                          $route.meta.navigationParent === subItemItem.title &&
                          'v-list-item--active'
                        "
                      >
                        <v-list-item-icon class="pl-3">
                          <v-icon color="white">
                            {{ subItemItem.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="pl-3">
                          <v-list-item-title class="caption">
                            {{ subItemItem.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-list-group>
                </template>
              </v-list>
            </v-list-group>
          </template>
        </v-list>
        <div
          v-show="false"
          class="pt-8 pb-2 text-center px-2"
        >
          <template v-if="!navigationMini">
            <v-btn
              v-if="!!deferredPrompt"
              block
              outlined
              class="btn-pwa"
              @click="installPWA"
            >
              <img
                class="download-icon mr-2"
                :src="DOWNLOAD_ICON"
              />
              <span class="caption">Zainstaluj {{ $t('home.title') }}</span>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              v-if="!!deferredPrompt"
              icon
            >
              <img
                class="download-icon"
                :src="DOWNLOAD_ICON"
              />
            </v-btn>
          </template>
        </div>
      </div>
    </v-navigation-drawer>
    <v-app-bar
      clipped-left
      app
      color="nav"
    >
      <v-app-bar-nav-icon
        color="white"
        @click="swtichMenu"
      />
      <div
        v-if="$vuetify.breakpoint.lgAndUp"
        class="d-flex align-center justify-center h-100 ml-2 mr-4 pr-4"
      >
        <a
          href="/"
          class="d-flex"
        >
          <img
            src="/img/nubowms-white.svg"
            alt=""
            :class="'logo--small'"
          />
        </a>
      </div>
      <template v-if="$vuetify.breakpoint.xlOnly">
        <v-toolbar-title
          v-if="$route.meta.submenu"
          class="toolbar__title"
        >
          <div class="body-1">{{ $route.meta.title }}:</div>
          <div class="font-weight-bold">
            {{ $route.meta.tabs ? $route.meta.navigationParent : $route.meta.name }}
          </div>
        </v-toolbar-title>
        <v-toolbar-title
          v-else
          class="toolbar__title"
        >
          {{ $route.meta.title }}
        </v-toolbar-title>

        <v-divider
          v-show="$route.meta.tabs"
          vertical
          class="pl-7 divider"
        />
        <v-tabs
          show-arrows
          class="pl-4 w-auto"
          :value="activeTabIndex"
          color="white"
          slider-size="3"
          active-class="tab__active"
        >
          <v-tab
            v-for="(tab, index) in $route.meta.tabs"
            :key="index"
            :href="tab.URI"
          >
            <span class="tab__title">{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <app-top-bar-user />
      </template>
      <template v-else-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar-title
          v-if="$route.meta.submenu"
          class="toolbar__title"
        >
          <div class="body-1">{{ $route.meta.title }}:</div>
          <div class="font-weight-bold">
            {{ $route.meta.tabs ? $route.meta.navigationParent : $route.meta.name }}
          </div>
        </v-toolbar-title>
        <v-toolbar-title
          v-else
          class="toolbar__title"
        >
          {{ $route.meta.title }}
        </v-toolbar-title>
        <v-divider
          v-show="$route.meta.tabs"
          vertical
          class="pl-7 divider"
        />
        <div class="d-flex justify-space-between align-center w-100">
          <div
            v-if="$route.meta.tabs"
            class="select__wrapper"
          >
            <v-select
              :items="$route.meta.tabs"
              :value="activeTab"
              item-text="title"
              item-value="name"
              hide-details
              class="ml-4"
              color="white"
              background-color="nav lighten-2"
              flat
              solo
              dark
              @change="onChangeTab"
            >
              <template v-slot:selection="{ item }">
                <span class="text--white">{{ item.title }}</span>
              </template>
            </v-select>
          </div>
          <v-divider v-else />
          <div>
            <app-top-bar-user />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex w-100 align-center">
          <v-select
            v-if="$route.meta.tabs"
            :items="$route.meta.tabs"
            :value="activeTab"
            item-text="title"
            item-value="name"
            hide-details
            color="white"
            background-color="nav lighten-2"
            flat
            solo
            dark
            @change="onChangeTab"
          >
            <template v-slot:selection="{ item }">
              <span class="text--white">{{ $route.meta.title }} - {{ item.title }}</span>
            </template>
          </v-select>
          <div
            v-else
            class="w-100 white--text"
          >
            {{ $route.meta.title }}
          </div>
          <app-top-bar-user />
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
// TODO: refactor
// TODO: fix import assets, import instead of require
import Vue from 'vue';
import AppTopBarUser from '@/components/shared/User.vue';
import { items } from '@/constants/NavBar';

const DOWNLOAD_ICON = require('@/assets/images/icons/monitor-arrow-down-variant.svg');

export default Vue.extend({
  name: 'SideNavigation',
  components: {
    AppTopBarUser,
  },
  data: () => ({
    sidebarMenu: null,
    navigationMini: false,
    loading: false,
    items,
    isOpenUserSettings: false,
    DOWNLOAD_ICON,
    deferredPrompt: false,
  }),
  computed: {
    activeTab() {
      const { tabs } = this.$route.meta;
      return tabs ? tabs[this.activeTabIndex] : {};
    },
    activeTabIndex() {
      const { meta, name } = this.$route;
      if (!name || !meta.tabs) {
        return null;
      }
      return meta.tabs.findIndex((tab) => tab.name === name);
    },
    navigationValue() {
      if (this.navigationMini && this.$vuetify.breakpoint.lgAndUp) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sidebarMenu = null;
        return true;
      }
      return false;
    },
  },
  watch: {
    navigationMini(newValue) {
      window.localStorage.setItem('navigationMini', `${newValue}`);
    },
  },
  created() {
    let deferredPrompt;

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      console.log('deferredPrompt', deferredPrompt);
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      console.log('beforeinstallprompt event was fired.');
    });
    window.addEventListener('appinstalled', () => {
      // Hide the app-provided install promotion
      // hideInstallPromotion();
      // Clear the deferredPrompt so it can be garbage collected
      this.deferredPrompt = null;
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed');
    });
  },
  methods: {
    swtichMenu() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.navigationMini = !this.navigationMini;
      } else {
        this.navigationMini = false;
        this.sidebarMenu = !this.sidebarMenu;
      }
    },
    onChangeTab(name) {
      this.$router.push({ name });
    },
    async installPWA() {
      // Hide the app provided install promotion
      // hideInstallPromotion();
      // Show the install prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await this.deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      this.deferredPrompt = null;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';
.logo {
  width: 200px;
  transition: width 0.3s ease;
  &--small {
    transition: width 0.3s ease;
    width: 170px;
  }
}
.w-auto {
  width: auto !important;
}
.toolbar__title,
.tab__title,
.user {
  color: $white;
}
.list__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: Calc(100% - 50px);
  @media (min-width: $lg) {
    height: 100%;
  }
}
.toolbar__title {
  overflow: unset !important;
  font-size: 18px;
}
.tab__active {
  background-color: rgba(255, 255, 255, 0.12);
}
.divider {
  border-color: $white !important;
}
.select__wrapper {
  @media (min-width: $md) {
    max-width: 250px;
  }
}
.download-icon {
  width: 22px;
  height: 20px;
}

.btn-pwa {
  border-color: $primary-lighten1;
}

::v-deep .v-list .v-list-item--active {
  .v-list-item__icon i.v-icon,
  .v-list-item__title {
    color: white !important;
  }
  &::before {
    opacity: 0 !important;
  }
}

::v-deep .v-list-group__header__append-icon {
  margin: 0px !important;
  min-width: 1.5rem !important;
  i.v-icon {
    color: $nav-lighten5 !important;
  }
}
::v-deep .v-list-item {
  //  padding: 0.125rem 0.75rem;
  &__icon {
    margin-right: 0.5rem !important;
  }
}
</style>
